.donation-buttons {
  display: flex;
  flex-wrap: wrap;
}

/* .donation-buttons .btn-group-toggle {
  display: flex;
  flex: 1;
} */

.donation-buttons .btn-primary,
.donation-buttons .btn-secondary {
  margin-right: 15px;
  margin-bottom: 20px;
  flex: 1 0 auto;
}

#denomination-other {
  display: flex !important;
  flex: 1 0 100%;
  align-items: center;
  margin-right: 15px;
  position: relative;
}

#denomination-other > input {
  margin: 0 !important;
}

#denomination-other > label {
  margin-right: 20px;
  margin-left: 15px;
  margin-bottom: 0;
}

#denomination-other input[type="number"] {
  color: #4d4d4d;
  font-weight: 400;
  text-align: center;
  background-color: rgb(235, 235, 235);
  padding: 13px 0;
  /* flex: 1 1 80%; */
  margin-right: 15px;
  border-radius: 4px;
  border: solid 1px rgb(128, 128, 128);
  position: absolute;
  top: 8px;
  right: 16px;
  width: 70%;
  height: 38px;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  #denomination-other input[type="number"] {
    width: 40%;
  }
}

#denomination-other input::placeholder {
  color: #000 !important;
  font-size: 18px;
  /* font-family: "ff-magda-clean-mono-web-pro", courier, sans-serif; */
}

#denomination-other input:hover {
  background-color: #d9d9d9;
}

.donation-buttons .btn.btn-secondary:last-of-type {
  margin-right: 0;
}
