.checkmark-container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.checkmark-container img {
  width: 150px;
  margin-bottom: 15px;
}

.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;

  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  visibility: visible !important;
}

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(1.1);
  }
  80% {
    -webkit-transform: scale(0.9);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

:focus::-webkit-input-placeholder {
  opacity: 0 !important;
}

:focus::-moz-placeholder {
  opacity: 0 !important;
}
::-webkit-input-placeholder {
  /* Chrome */
  opacity: 0.5 !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.5 !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.5 !important;
}
