#premiums-container .card {
  margin-top: 15px;
  margin-bottom: 15px;
  height: calc(100% - 30px);
}

#premiums-container .card .card-img-top {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  min-height: 150px;
  justify-content: center;
}

#premiums-container .card .card-img-center {
  max-height: 200px;
  max-width: calc(100% - 20px);
}

#premiums-container .card-subtitle span {
  display: block;
}

#premiums-container .card .card-text {
  max-height: 4.5em;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

#premiums-container .card .card-text.show {
  max-height: 80em;
}

#premiums-container .card .btn.btn-link {
  width: 100%;
  text-align: center;
  margin-top: -15px;
  margin-bottom: -10px;
  padding: 0;
}

/* PREMIUMS LIST */

#premiums-container {
  margin-top: 30px;
  margin-bottom: 30px;
}
#premiumsTabContent {
  padding-top: 20px;
}
